/**
 * エクスポート履歴
 */
import { isBlank } from '@/utilities/typing'
import ImportType from '../enums/import-type';
import ProcessStatus from '../enums/process-status';
import ImportFailure from '../enums/import-failure';

class Import {
    import_id;
    reserved_at;
    process_status;
    import_type;
    condition;
    import_filename;
    failure;
    file_deleted;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.import_id = properties.import_id;
        this.reserved_at = properties.reserved_at;
        this.process_status = properties.process_status;
        this.import_type = properties.import_type;
        this.condition = properties.condition;
        this.import_filename = properties.import_filename;
        this.failure = properties.failure;
        this.file_deleted = properties.file_deleted;
    }

    get process_status_label() {
        return ProcessStatus.get(this.process_status);
    }

    get import_type_label() {
        return ImportType.get(this.import_type);
    }

    get failure_label() {
        return ImportFailure.get(this.failure);
    }

    get condition_display() {
        let display = "";

        Object.keys(this.condition).forEach((key) => {
            let value = this.condition[key];

            const labels = {
                handle_department: "未登録の部門",
                handle_customer: "未登録の顧客",
                handle_project_category: "未登録の案件分類",
                handle_user: "未登録のユーザ",
                handle_project: "未登録の案件",
                handle_phase: "未登録のフェーズ",
                handle_work_category: "未登録の作業カテゴリ",
            };
            const label = labels[key] ?? key;

            if ([
                'handle_department',
                'handle_customer',
                'handle_project_category',
                'handle_user',
                'handle_project',
                'handle_phase',
                'handle_work_category',
            ].includes(key)) {
                if (value === 2) {
                    value = "自動作成";
                } else {
                    value = "エラーとする";
                }
            }


            display += label + " : " + value + "\n";
        });

        return display;
    }
}

export default Import;
