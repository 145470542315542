<template>
    <header class="page-title">
        <h1><i class="bi bi-upload"></i> インポート履歴</h1>
        <button class="btn btn-outline-secondary" @click="$router.go(-1)"><i class="bi bi-reply"></i> 戻る</button>
    </header>

    <section class="section">
        <div class="mb-3 text-end">
            <button class="btn btn-outline-primary" @click="search"><i class="bi bi-arrow-clockwise"></i> 履歴更新</button>
        </div>
        <table class="table">
            <thead class="table-dark text-center">
                <tr>
                    <th>ファイル種別</th>
                    <th>予約日時</th>
                    <th>ファイル名</th>
                    <th>取込条件</th>
                    <th>ステータス</th>
                    <th>失敗理由</th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(3)" :key="i">
                    <td><text-loader :width="10"></text-loader></td>
                    <td><text-loader :width="10"></text-loader></td>
                    <td><text-loader :width="10"></text-loader></td>
                    <td><text-loader :width="14"></text-loader></td>
                    <td><text-loader :width="6"></text-loader></td>
                    <td><text-loader :width="14"></text-loader></td>
                </tr>
            </tbody>
            <tbody v-else-if="imports.length === 0">
                <tr>
                    <td colspan="5">インポート履歴がありません</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="imp of imports" :key="imp.import_id">
                    <td>{{ imp.import_type_label }}</td>
                    <td>{{ $helper.date('Y/m/d H:i', imp.reserved_at) }}</td>
                    <td><button class="btn btn-link p-0" @click="download(imp)">{{ imp.import_filename }}</button></td>
                    <td class="prewrap">{{ imp.condition_display }}</td>
                    <td>{{ imp.process_status_label }}</td>
                    <td>
                        <p v-if="imp.process_status === ProcessStatus.FAILED">{{ imp.failure_label }}</p>
                        <button v-if="imp.process_status === ProcessStatus.FAILED && imp.failure === ImportFailure.VALIDATION_FAILED" class="btn btn-sm btn-outline-danger" @click="downloadError(imp)"><i class="bi bi-download"></i> エラー内容ダウンロード</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <paginator :meta="paginator" @move="search" v-if="paginator"></paginator>
    </section>
</template>

<script>
import Paginator from '@/components/tools/Paginator';
import Import from "@/models/entities/import";
import TextLoader from '@/components/tools/TextLoader';
import ProcessStatus from '@/models/enums/process-status';
import ImportFailure from '@/models/enums/import-failure';
import {downloadCsv} from '@/utilities/download';

export default {
    name: 'ImportHistory',
    components: {
        Paginator,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            imports: [],
            loading: false,
            paginator: null,

            ProcessStatus,
            ImportFailure,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        search(page = 1) {
            this.imports.splice(0);
            this.paginator = null;
            this.loading = true;
            this.$http.get('/import', {params: {page}})
            .then((response) => {
                this.imports = response.data.data.map((row) => {return new Import(row)});
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        download(imp) {
            this.startScreenLoading();
            this.$http.get(`/import/${imp.import_id}/download`)
            .then((response) => {
                downloadCsv(response.data, imp.import_filename);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        downloadError(imp) {
            this.startScreenLoading();
            this.$http.get(`/import/${imp.import_id}/error/download`)
            .then((response) => {
                downloadCsv(response.data, "error_" + imp.import_id + ".csv");
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>
