import Enum from './enum'

/**
 * インポートファイル種別
 */
class ImportFailure extends Enum {
    static HEADER_MISMATCH = 1;
    static ROWCOUNT_INCORRECT = 2;
    static VALIDATION_FAILED = 9;

    static values() {
        return {
            [this.HEADER_MISMATCH]: "ヘッダ項目が正しくありません (または文字コードが Shift JIS ではありません)。",
            [this.ROWCOUNT_INCORRECT]: "項目数が、ヘッダの項目数と一致しない行があります。",
            [this.VALIDATION_FAILED]: "データに問題があります。エラーCSVを確認してください。",
        }
    }
}

export default ImportFailure;
